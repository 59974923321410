/*=========================================================================================
File Name: sidebarItems.js
Description: Sidebar Items list. Add / Remove menu items from here.
Strucutre:
url     => router path
name    => name to display in sidebar
slug    => router path name
icon    => Feather Icon component/icon name
tag     => text to display on badge
tagColor  => class to apply on badge element
i18n    => Internationalization
submenu   => submenu of current item (current item will become dropdown )
NOTE: Submenu don't have any icon(you can add icon if u want to display)
isDisabled  => disable sidebar item/group
----------------------------------------------------------------------------------------
Item Name: Vuesax Admin - VueJS Dashboard Admin Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
	{
		url: "/",
		name: "Home",
		slug: "home",
		icon: "HomeIcon",
	},
	{
		url: "/search",
		name: "Search",
		slug: "search",
		icon: "SearchIcon",
	},
	{
		url: "/new-registrations",
		name: "Registrations",
		slug: "new-registrations",
		icon: "MenuIcon",
	},
	{
		url: null,
		name: "Abuses",
		icon: "AlertTriangleIcon",
		i18n: "Abuses",
		submenu: [
			{
				url: null,
				name: "Abuse Reported",
				i18n: "Abuse Reported",
				submenu: [
					{
						url: "/abuse-reported",
						name: "External Lists",
						slug: "abuse-reported",
						i18n: "External Lists",
					},
					{
						url: "https://talos.watchtower.space",
						name: "Talos",
						slug: "external",
						i18n: "Talos",
						target: "_blank"
					},
				]
			},
			{
				url: null,
				name: "Suspensions",
				i18n: "Suspensions",
				submenu: [
					{
						url: "/suspension",
						name: "Suspend",
						slug: "suspension",
						i18n: "Suspend",
						admin: true,
					},
					{
						url: "/suspension-list",
						name: "List",
						slug: "suspension-list",
						i18n: "List",
					},
					{
						url: "/suspension-blocked",
						name: "Blocked",
						slug: "suspension-blocked",
						i18n: "Blocked",
					},
					{
						url: "/suspension-not-actionable",
						name: "Not Actionable",
						slug: "suspension-not-actionable",
						i18n: "Not Actionable",
					},
				]
			},
			{
				url: null,
				name: "Unsuspensions",
				i18n: "Unsuspensions",
				submenu: [
					{
						url: "/unsuspension",
						name: "Unsuspend",
						slug: "unsuspension",
						i18n: "Unsuspend",
						admin: true,
					},
					{
						url: "/unsuspension-list",
						name: "List",
						slug: "unsuspension-list",
						i18n: "List",
					},
					{
						url: "/unsuspension-not-actionable",
						name: "Not Actionable",
						slug: "unsuspension-not-actionable",
						i18n: "Not Actionable",
					},
					{
						url: "/unsuspension-requests",
						name: "Requests",
						slug: "unsuspension-requests",
						i18n: "Requests",
					},
				]
			},
			{
				url: null,
				name: "Registry Lock",
				i18n: "Registry Lock",
				submenu: [
					{
						url: "/registry-lock",
						name: "Lock / Unlock",
						slug: "registry-lock",
						i18n: "Lock / Unlock",
						admin: true,
					},
					{
						url: "/registry-lock-list",
						name: "List",
						slug: "registry-lock-list",
						i18n: "List",
					},
				]
			},
			{
				url: null,
				name: "Source Detection",
				i18n: "Source Detection",
				submenu: [
					{
						url: "/source-detection/radix-suspension-requests",
						name: "Radix Website",
						slug: "radix-suspension-requests",
						i18n: "Radix Website",
					},
					{
						url: "/source-detection/artists-against-419",
						name: "Artists Against 419",
						slug: "artists-against-419",
						i18n: "Artists Against 419",
					},
					{
						url: "/source-detection/easysol",
						name: "Easysol",
						slug: "easysol",
						i18n: "Easysol",
					},
					{
						url: "/source-detection/google-safe-browsing",
						name: "Google Safe Browsing",
						slug: "google-safe-browsing",
						i18n: "Google Safe Browsing",
					},
					{
						url: "/source-detection/malware-domains",
						name: "Malware Domains",
						slug: "malware-domains",
						i18n: "Malware Domains",
					},
					{
						url: "/source-detection/netcraft",
						name: "Netcraft",
						slug: "netcraft",
						i18n: "Netcraft",
					},
					{
						url: "/source-detection/ntldstats",
						name: "nTLDstats",
						slug: "ntldstats",
						i18n: "nTLDstats",
					},
					{
						url: "/source-detection/phishlabs",
						name: "Phishlabs",
						slug: "phishlabs",
						i18n: "Phishlabs",
					},
					{
						url: "/source-detection/phishtank",
						name: "Phishtank",
						slug: "phishtank",
						i18n: "Phishtank",
					},
					{
						url: "/source-detection/segasec",
						name: "Segasec",
						slug: "segasec",
						i18n: "Segasec",
					},
					{
						url: "/source-detection/spamhaus",
						name: "Spamhaus",
						slug: "spamhaus",
						i18n: "Spamhaus",
					},
					{
						url: "/source-detection/spamhaus-dbl",
						name: "Spamhaus DBL",
						slug: "spamhaus-dbl",
						i18n: "Spamhaus DBL",
					},
					{
						url: "/source-detection/surbl",
						name: "Surbl",
						slug: "surbl",
						i18n: "Surbl",
					},
					{
						url: "/source-detection/symantec",
						name: "Symantec",
						slug: "symantec",
						i18n: "Symantec",
					},
					{
						url: "/source-detection/urlhaus",
						name: "URLhaus",
						slug: "urlhaus",
						i18n: "URLhaus",
					},
					{
						url: "/source-detection/ctm360",
						name: "CTM360",
						slug: "ctm360",
						i18n: "CTM360",
					},
					{
						url: "/source-detection/cybercrimecenter",
						name: "C3 Cyber Crime Center",
						slug: "cybercrimecenter",
						i18n: "C3 Cyber Crime Center",
					},
					{
						url: "/source-detection/memcyco",
						name: "MemcyCo",
						slug: "memcyco",
						i18n: "MemcyCo",
					},
					{
						url: "/source-detection/cybergs",
						name: "Cybergs",
						slug: "cybergs",
						i18n: "Cybergs",
					},
					{
						url: "/source-detection/hispasec",
						name: "Hispasec",
						slug: "hispasec",
						i18n: "Hispasec",
					},
					{
						url: "/source-detection/dga-feed",
						name: "DGA Dump",
						slug: "dga-feed",
						i18n: "DGA Dump",
					},
					{
						url: "/source-detection/dga-regex",
						name: "DGA Regex",
						slug: "dga-regex",
						i18n: "DGA Regex",
					},
					{
						url: "/source-detection/dga-api",
						name: "DGA API",
						slug: "dga-api",
						i18n: "DGA API",
					},
					{
						url: "/source-detection/openphish",
						name: "Openphish",
						slug: "openphish",
						i18n: "Openphish",
					},
					{
						url: "/source-detection/orange",
						name: "Orange",
						slug: "orange",
						i18n: "Orange",
					},
					{
						url: "/source-detection/netcraft-scrapped",
						name: "Netcraft Scrapped",
						slug: "netcraft-scrapped",
						i18n: "Netcraft Scrapped",
					},
				]
			},
			{
				url: "/action-review",
				name: "Action Review",
				slug: "action-review",
				i18n: "Action Review",
			},
		]
	},
	// {
	// 	url: "/anomalies",
	// 	name: "Anomalies",
	// 	slug: "anomalies",
	// 	icon: "ActivityIcon",
	// },
	{
		url: null,
		name: "Highlights",
		icon: "ActivityIcon",
		i18n: "Highlights",
		admin: true,
		submenu: [
			{
				url: "/registrant-abuse-rate",
				name: "Registrant Abuse Rate",
				slug: "registrant-abuse-rate",
				i18n: "Registrant Abuse Rate"
			},
			{
				url: "/bulk-registrations",
				name: "Bulk Registrations",
				slug: "bulk-registrations",
				i18n: "Bulk Registrations"
			},
			{
				url: "/daily-patterns",
				name: "Daily Patterns",
				slug: "daily-patterns",
				i18n: "Daily Patterns"
			},
			{
				url: "/abusive-daily-patterns",
				name: "Abusive Daily Patterns",
				slug: "abusive-daily-patterns",
				i18n: "Abusive Daily Patterns"
			},
		]
	},
	{
		url: null,
		name: "Rule Management",
		icon: "ShieldIcon",
		i18n: "Rule Management",
		admin: true,
		submenu: [
			{
				url: "/watchlist",
				name: "Watchlist",
				slug: "watchlist",
				i18n: "Watchlist"
			},
			{
				url: "/saved-filters",
				name: "Saved Filters",
				slug: "saved-filters",
				i18n: "Saved Filters"
			},
			{
				url: null,
				name: "RTS Rules",
				i18n: "RTS Rules",
				submenu: [
					{
						url: "/create-rts-rule",
						name: "Create",
						slug: "create-rts-rule",
						i18n: "Create",
					},
					{
						url: "/rts-rule-list",
						name: "List",
						slug: "rts-rule-list",
						i18n: "List",
					},
				]
			},
			{
				url: null,
				name: "RTK Rules",
				i18n: "RTK Rules",
				submenu: [
					{
						url: "/create-rtk-rule",
						name: "Create",
						slug: "create-rtk-rule",
						i18n: "Create",
					},
					{
						url: "/rtk-rule-list",
						name: "List",
						slug: "rtk-rule-list",
						i18n: "List",
					},
					{
						url: "/rtk-rule-watchlist",
						name: "Watchlist",
						slug: "rtk-rule-watchlist",
						i18n: "Watchlist",
					},
				]
			},
			{
				url: null,
				name: "Blacklist",
				i18n: "Blacklist",
				submenu: [
					{
						url: "/add-blacklist-keyword",
						name: "Add Keyword",
						slug: "add-blacklist-keyword",
						i18n: "Add Keyword",
					},
					{
						url: "/blacklist-keyword-list",
						name: "Keyword List",
						slug: "blacklist-keyword-list",
						i18n: "Keyword List",
					},
					// {
					// 	url: "/add-blacklist-registrant",
					// 	name: "Add Registrant",
					// 	slug: "add-blacklist-registrant",
					// 	i18n: "Add Registrant",
					// },
					{
						url: "/blacklist-registrant-list",
						name: "Registrant List",
						slug: "blacklist-registrant-list",
						i18n: "Registrant List",
					},
				]
			},
			{
				url: null,
				name: "Whitelist",
				i18n: "Whitelist",
				submenu: [
					{
						url: "/whitelist",
						name: "List",
						slug: "whitelist",
						i18n: "List"
					},
					{
						url: "/whitelist-alerts",
						name: "Whitelist Alerts",
						slug: "whitelist-alerts",
						i18n: "Whitelist Alerts",
					},
				]
			},
			{
				url: null,
				name: "Request Sources",
				i18n: "Request Sources",
				submenu: [
					{
						url: "/request-sources",
						name: "List",
						slug: "request-sources",
						i18n: "List",
					},
					{
						url: "/trusted-sources",
						name: "Trusted Sources",
						slug: "trusted-sources",
						i18n: "Trusted Sources",
					},
				]
			},
		]
	},
	{
		url: "/registrars",
		name: "Registrars",
		slug: "registrars",
		icon: "GridIcon",
		admin: true,
	},
	{
		url: "/dns-twister",
		name: "DNS Twister",
		slug: "dns-twister",
		icon: "RadioIcon",
	},
	{
		url: null,
		name: "Reports",
		icon: "PieChartIcon",
		i18n: "Reports",
		// admin: true,
		submenu: [
			{
				url: "https://reports.radixbi.tech/views/WatchtowerReports/RegistrarAnalysis?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no",
				name: "Tableau",
				slug: "external",
				target: "_blank",
				i18n: "Tableau"
			},
			{
				url: "/registrar-report",
				name: "Registrar Report",
				slug: "registrar-report",
				i18n: "Registrar Report"
			},
		]
	}
]
