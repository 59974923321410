<template>
    <div id="global-filter">
        <vs-sidebar hidden-background position-right v-model="active" click-not-close class="items-no-padding">
            <div class="h-full">
                <div class="customizer-header mt-6 flex items-center justify-between px-6">
                    <div>
                        <h4>Global Filters</h4>
                        <small>Set filters to dashboard data</small>
                    </div>
                    <feather-icon icon="XIcon" @click.stop="active = false" class="cursor-pointer"></feather-icon>
                </div>
                <vs-divider class="mb-0"></vs-divider>

                <VuePerfectScrollbar class="scroll-area--global-filter pt-4 pb-6" :settings="settings">
                    <div class="px-6">

                        <div>
                            <h5 class="mb-4">Customize Date View</h5>
                            <div>
                                <vs-radio class="mr-4" v-model="globalFilters['dateView']" vs-value="weekly">Weekly</vs-radio>
                                <vs-radio class="mr-4" v-model="globalFilters['dateView']" vs-value="monthly">Monthly</vs-radio>
                                <vs-radio class="mr-4" v-model="globalFilters['dateView']" vs-value="yearly">Yearly</vs-radio>
                                <vs-radio class="mr-4" v-model="globalFilters['dateView']" vs-value="custom">Custom</vs-radio>
                            </div>
                        </div>
                        <vs-divider></vs-divider>
                        
                        <template v-if="globalFilters['dateView'] == 'custom'">
                            <div>
                                <h5 class="mb-4">Date Range</h5>
                                <div class="mb-2">
                                    <datepicker placeholder="Start Date" v-model="globalFilters['startDate']"></datepicker>
                                </div>
                                <div>
                                    <datepicker placeholder="End Date" v-model="globalFilters['endDate']"></datepicker>
                                </div>
                            </div>
                            <vs-divider></vs-divider>
                        </template>
                        
                        <template v-if="globalFilters['dateView'] == 'weekly'">
                            <div>
                                <h5 class="mb-4">Custom Week <small v-if="globalFilters['customWeek']" class="mt-2">({{ $moment(globalFilters['customWeek']).startOf('week').format('DD MMM YYYY') }} - {{ $moment(globalFilters['customWeek']).endOf('week').format('DD MMM YYYY') }})</small></h5>
                                <div>
                                    <datepicker placeholder="Select Week" v-model="globalFilters['customWeek']"></datepicker>
                                </div>
                            </div>
                            <vs-divider></vs-divider>
                        </template>
                        
                        <template v-if="globalFilters['dateView'] == 'monthly'">
                            <div>
                                <h5 class="mb-4">Custom Month</h5>
                                <div>
                                    <datepicker placeholder="Select Month" v-model="globalFilters['customMonth']" :minimumView="'month'" :maximumView="'year'" format="MMM yyyy"></datepicker>
                                </div>
                            </div>
                            <vs-divider></vs-divider>
                        </template>
                        
                        <template v-if="globalFilters['dateView'] == 'yearly'">
                            <div>
                                <h5 class="mb-4">Custom Year</h5>
                                <div>
                                    <datepicker placeholder="Select Year" v-model="globalFilters['customYear']" :minimumView="'year'" :maximumView="'year'" format="yyyy"></datepicker>
                                </div>
                            </div>
                            <vs-divider></vs-divider>
                        </template>
                        
                        <div>
                            <h5 class="mb-4">TLD</h5>
                            <div>
                                <v-select class="w-full" v-model="globalFilters['tld']" :options="formTLD" multiple></v-select>
                            </div>
                        </div>
                        <vs-divider></vs-divider>
                        
                        <div>
                            <h5 class="mb-4">Registrar</h5>
                            <div>
                                <v-select class="w-full" v-model="globalFilters['registrar']" :options="formRegistrar" multiple></v-select>
                            </div>
                        </div>
                        <vs-divider></vs-divider>
                        
                        <div>
                            <div class="vx-row mb-4">
                                <div class="vx-col sm:w-1/2 w-full mb-2">
                                    <vs-button class="block w-full" @click="applyGlobalFilters()">Apply</vs-button>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-2">
                                    <vs-button class="block w-full" color="danger" type="border" @click="resetGlobalFilters()">Reset</vs-button>
                                </div>
                            </div>
                        </div>

                    </div>
                </VuePerfectScrollbar>
            </div>
        </vs-sidebar>
    </div>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'

    export default {
        props: {
            navbarType: {
                type: String,
                required: true,
            },
            navbarColor: {
                type: String,
                required: true,
                default: "#fff",
            },
            footerType: {
                type: String,
                required: true,
            },
            routerTransition: {
                type: String,
                required: true,
            },
            hideScrollToTop: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                active: false,
                globalFilters: {
                    dateView: 'weekly',
                    startDate: null,
                    endDate: null,
                    customWeek: null,
                    customMonth: null,
                    customYear: null,
                    tld: [],
                    registrar: [],
                },
                initData: {
                    tld: [],
                    registrar: []
                },
                settings: { // perfectscrollbar settings
                    maxScrollbarLength: 60,
                    wheelSpeed: .60,
                },
            }
        },
        watch: {
            'globalFilters.dateView': function() {
                this.globalFilters['startDate'] = null;
                this.globalFilters['endDate'] = null;
                this.globalFilters['customWeek'] = null;
                this.globalFilters['customMonth'] = null;
                this.globalFilters['customYear'] = null;
            }
        },
        computed: {
			formTLD: function(){
				let tldArray = this.initData['tld'].map(x => {
					return {
						label: x['tld'],
						value: x['id']
					}
				});
				
				return tldArray;
			},
			formRegistrar: function(){
				let registrarArray = this.initData['registrar'].map(x => {
					return {
						label: x['organization'],
						value: x['registrar_id']
					}
				});
				
				return registrarArray;
			},
        },
        methods: {
            applyGlobalFilters() {
                this.$root.$emit('applyGlobalFilters', {
                    dateView: this.globalFilters['dateView'],
                    startDate: this.globalFilters['startDate'] ? this.$moment.utc(this.globalFilters['startDate']).local().format('DD-MM-YYYY') : null,
                    endDate: this.globalFilters['endDate'] ? this.$moment.utc(this.globalFilters['endDate']).local().format('DD-MM-YYYY') : null,
                    customWeek: this.globalFilters['customWeek'] ? this.$moment.utc(this.globalFilters['customWeek']).local().format('DD-MM-YYYY') : null,
                    customMonth: this.globalFilters['customMonth'] ? this.$moment.utc(this.globalFilters['customMonth']).local().format('DD-MM-YYYY') : null,
                    customYear: this.globalFilters['customYear'] ? this.$moment.utc(this.globalFilters['customYear']).local().format('DD-MM-YYYY') : null,
                    tld: this.globalFilters['tld'] ? this.globalFilters['tld'].map(x => x['label']) : null,
                    registrar: this.globalFilters['registrar'] ? this.globalFilters['registrar'].map(x => x['value']) : null,
                });
                this.active = false;
            },
            resetGlobalFilters() {
                this.$root.$emit('resetGlobalFilters');
                this.globalFilters['dateView'] = 'weekly',
                this.globalFilters['startDate'] = null;
                this.globalFilters['endDate'] = null;
                this.globalFilters['customWeek'] = null;
                this.globalFilters['customMonth'] = null;
                this.globalFilters['customYear'] = null;
                this.globalFilters['tld'] = [];
                this.globalFilters['registrar'] = [];
                this.active = false;
            },
        },
        components: {
            VuePerfectScrollbar,
            Datepicker,
            'v-select': vSelect,
        },
        mounted() {
			let self = this;
            // Toggle show / hide
            this.$root.$on('toggleGlobalFilters', () => {
                this.active = !this.active;
            });
            // Toggle hide
            this.$root.$on('hideGlobalFilters', () => {
                this.active = false;
            });
			// Get TLD JSON
			this.$axiosSecure.get('/get-tlds')
			.then((response) => {
				self.initData['tld'] = response.data;
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
			});
			// Get Registrars JSON
			this.$axiosSecure.get('/get-registrars')
			.then((response) => {
				self.initData['registrar'] = response.data;
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
			});
        }
    }
</script>

<style lang="scss">
#global-filter {
    .vs-sidebar{
        position: fixed;
        z-index: 52000;
        width: 400px;
        max-width: 90vw;
    }
}

.scroll-area--global-filter {
    height: calc(100% - 5rem);
}
</style>
