<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="layout--main" :class="[navbarClasses, footerClasses, {'app-page': isAppPage}]">

        <global-filter
            :navbarType="navbarType"
            :navbarColor="navbarColor"
            :footerType="footerType"
            :routerTransition="routerTransition"
            v-if="!disableCustomizer"
            :hideScrollToTop="hideScrollToTop"
            />

        <vx-sidebar :sidebarItems="sidebarItems" :logo="require('@/assets/images/logo/logo.png')" title="Watchtower" parent=".layout--main" />

        <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">

            <div id="content-overlay"></div>

            <div class="content-wrapper">

                <the-navbar :navbarColor="navbarColor" :class="[{'text-white': isNavbarDark && !isThemeDark}, {'text-base': !isNavbarDark && isThemeDark}]" />

                <div class="router-view">
                    <div class="router-content" :class="{'mt-0': navbarType == 'hidden'}">
                        <transition :name="routerTransition">
                        <div class="router-header flex flex-wrap items-center mb-6" v-if="$route.meta.breadcrumb || $route.meta.pageTitle">
                            <div class="content-area__heading" :class="{'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light' : $route.meta.breadcrumb}">
                                <h2 class="mb-1">{{ routeTitle }}</h2>
                            </div>
                            <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" />
                            <div class="ml-auto flex flex-wrap items-center justify-between">
                                <div class="badge badge--dark mr-4 hidden md:inline-flex" v-if="listingCount['total']">{{ formattedFilteredCount }} / {{ listingCount['total'] | formatNumber }}</div>
								<vs-dropdown v-if="pageButtons['daterange']" vs-custom-content vs-trigger-click class="mr-4">
                                    <vx-tooltip text="Date Range" position="bottom">
                                        <vs-button v-if="dateRangeActive" icon="icon-calendar" icon-pack="feather" class="md:block pointer-events-auto"></vs-button>
                                        <vs-button v-else type="border" icon="icon-calendar" icon-pack="feather" class="md:block pointer-events-auto"></vs-button>
                                    </vx-tooltip>

                                    <vs-dropdown-menu class="mw-full">
                                        <div class="w-64 p-3 text-center">
											<div class="mb-2">
												<v-select class="w-full" placeholder="Select Column" v-model="dateRange['column']" :options="dateRangeColumns" :reset-on-options-change="true"></v-select>
											</div>

											<div class="mb-2">
												<datepicker placeholder="Start Date" v-model="dateRange['startDate']"></datepicker>
											</div>
											<div class="mb-2">
												<datepicker placeholder="End Date" v-model="dateRange['endDate']"></datepicker>
											</div>

											<template v-if="!dateRangeActive">
												<vs-button class="block w-full" color="dark" type="border" :disabled="dateRange['column'] == '' || dateRange['startDate'] == null || dateRange['endDate'] == null" @click="applyDateRange()">Apply</vs-button>
											</template>
											<template v-else>
												<vs-button class="block w-full" color="danger" type="border" @click="resetDateRange()">Reset</vs-button>
											</template>
                                        </div>
                                    </vs-dropdown-menu>
                                </vs-dropdown>
                                <vs-dropdown v-if="pageButtons['filter'] && user_role == 'admin'" vs-custom-content vs-trigger-click class="mr-4">
                                    <vx-tooltip text="Quick Filter" position="bottom">
                                        <vs-button type="border" icon="icon-filter" icon-pack="feather" class="md:block pointer-events-auto"></vs-button>
                                    </vx-tooltip>

                                    <vs-dropdown-menu class="mw-full">
                                        <div class="w-64 p-3 text-center">
                                            <v-select class="w-full mb-2" placeholder="Select Filter" :value="tableFilterValue" :options="tableFilterOptions" :reset-on-options-change="true" @input="applyFilter"></v-select>
                                            <vs-button color="dark" type="border" class="block w-full" :disabled="!tableFilterActive" @click="saveFilterPrompt">Save Filter</vs-button>
											<template v-if="pageButtons['blacklist']">
												<hr class="mt-4 mb-3">
												<div class="vx-row text-left">
													<div class="vx-col w-2/3"><span>Keyword Filter</span></div>
													<div class="vx-col w-1/3">
														<vs-switch class="w-full" color="success" v-model="blacklistFilter"></vs-switch>
													</div>
												</div>
											</template>
                                        </div>
                                    </vs-dropdown-menu>
                                </vs-dropdown>
                                <vx-tooltip text="CSV Export" position="bottom" class="mr-4">
                                    <vs-button v-if="pageButtons['download']" type="border" icon="icon-download" icon-pack="feather" class="md:block" @click="downloadPage()"></vs-button>
                                </vx-tooltip>
                                <vx-tooltip text="Reset Table" position="bottom">
                                    <vs-button v-if="pageButtons['refresh']" type="border" icon="icon-refresh-cw" icon-pack="feather" class="md:block" @click="refreshPage()"></vs-button>
                                </vx-tooltip>
                            </div>
                        </div>
                        </transition>
                        <div class="content-area__content">
                            <back-to-top bottom="5%" visibleoffset="500" v-if="!hideScrollToTop">
                                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg" />
                            </back-to-top>
                            <transition :name="routerTransition" mode="out-in">
                                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
                            </transition>
                        </div>
                    </div>
                </div>

            </div>

            <vs-prompt vs-title="Enter filter title" :vs-is-valid="tableFilter['title'] != ''" @vs-cancel="tableFilter['title']=''" @vs-accept="saveFilter" :vs-active.sync="tableFilter['prompt']">
                <div class="con-exemple-prompt">
                    <vs-input v-model="tableFilter['title']" class="mt-3 w-full" />
                </div>
            </vs-prompt>

            <the-footer></the-footer>
        </div>
    </div>
</template>

<script>
	import VxSidebar from '@/layouts/components/vx-sidebar/VxSidebar.vue';
	import GlobalFilter from "../components/global-filter/GlobalFilter.vue";
	import TheNavbar from '../components/TheNavbar.vue';
	import TheFooter from '../components/TheFooter.vue';
	import themeConfig from '@/../themeConfig.js';
	import sidebarItems from "@/layouts/components/vx-sidebar/sidebarItems.js";
	import BackToTop from 'vue-backtotop'
	import vSelect from 'vue-select'
    import Datepicker from 'vuejs-datepicker';

	export default {
		data() {
			return {
				navbarType: themeConfig.navbarType || 'floating',
				navbarColor: themeConfig.navbarColor || '#fff',
				footerType: themeConfig.footerType || 'static',
				routerTransition: themeConfig.routerTransition || 'none',
				isNavbarDark: false,
				routeTitle: this.$route.meta.pageTitle,
				sidebarItems: sidebarItems,
				disableCustomizer: themeConfig.disableCustomizer,
				windowWidth: window.innerWidth, //width of windows
				hideScrollToTop: themeConfig.hideScrollToTop,
				disableThemeTour: themeConfig.disableThemeTour,
				dateRange: {
					column: '',
					startDate: null,
                    endDate: null
				},
				tableFilter: {
					title: '',
					prompt: false
				},
				blacklistFilter: false
			}
		},
		watch: {
			'$route'() {
				this.routeTitle = this.$route.meta.pageTitle;
			},
			blacklistFilter(val) {
				this.blacklistedFilter(val)
			},
		},
		computed: {
			isAppPage() {
				if(this.$route.path.includes('/apps/')) return true
				else return false
			},
			isThemeDark() { return this.$store.state.theme == 'dark' },
			sidebarWidth() {
				return this.$store.state.sidebarWidth;
			},
			bodyOverlay() {
				return this.$store.state.bodyOverlay;
			},
			listingCount() {
				return this.$store.getters.getListingCount;
			},
			dateRangeColumns() {
				return this.$store.getters.getDateRangeColumns;
			},
			dateRangeActive() {
				return this.$store.getters.getDateRangeActive;
			},
			tableFilterValue() {
				return this.$store.getters.getTableFilterValue;
			},
			tableFilterOptions() {
				return this.$store.getters.getTableFilterOptions;
			},
			tableFilterActive() {
				return this.$store.getters.getTableFilterActive;
			},
			formattedFilteredCount() {
				if (this.listingCount['filtered']) {
					let count = this.listingCount['filtered'];

					if(count == 1) {
						return `1`;
					}
					else if((count - 1) % 1000 == 0) {
						return `${this.$options.filters.formatNumber(count - 1)}+`;
					}
					else {
						return this.$options.filters.formatNumber(this.listingCount['filtered']);
					}
				}
				else {
					return 0;
				}
			},
			contentAreaClass() {
				if(this.sidebarWidth == "default") return "content-area-default"
				else if(this.sidebarWidth == "reduced") return "content-area-reduced"
				else if(this.sidebarWidth) return "content-area-full"
			},
			navbarClasses() {
				return {
					'navbar-hidden': this.navbarType == 'hidden',
					'navbar-sticky': this.navbarType == 'sticky',
					'navbar-static': this.navbarType == 'static',
					'navbar-floating': this.navbarType == 'floating',
				}
			},
			footerClasses() {
				return {
					'footer-hidden': this.footerType == 'hidden',
					'footer-sticky': this.footerType == 'sticky',
					'footer-static': this.footerType == 'static',
				}
			},
			pageButtons() {
				return {
					refresh: this.$route.meta.buttons ? this.$route.meta.buttons.refresh : false,
					download: this.$route.meta.buttons ? this.$route.meta.buttons.download : false,
					filter: this.$route.meta.buttons ? this.$route.meta.buttons.filter : false,
					daterange: this.$route.meta.buttons ? this.$route.meta.buttons.daterange : false,
					blacklist: this.$route.meta.buttons ? this.$route.meta.buttons.blacklist : false
				}
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			changeRouteTitle(title) {
				this.routeTitle = title;
			},
			handleWindowResize(event) {
				this.windowWidth = event.currentTarget.innerWidth;
				this.setSidebarWidth();
			},
			setSidebarWidth() {
				if (this.windowWidth < 1200) {
					this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)
					this.$store.dispatch('updateSidebarWidth', 'no-sidebar')
					this.disableThemeTour = true;
				}
				else if(this.windowWidth < 1200) {
					this.$store.dispatch('updateSidebarWidth', 'reduced')
				}
				else {
					this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true)
				}
			},
			toggleHideScrollToTop(val) {
				this.hideScrollToTop = val;
			},
			refreshPage() {
				this.$root.$emit('refreshPage');
			},
			downloadPage() {
				this.$root.$emit('downloadPage');
			},
			applyDateRange() {
				this.$store.dispatch('setDateRangeValue', this.dateRange);
			},
			resetDateRange() {
				// Clear local data
				this.dateRange['column'] = '';
				this.dateRange['startDate'] = null;
				this.dateRange['endDate'] = null;

				// Clear vuex data
				this.$store.dispatch('clearDateRangeValue');
				this.$store.dispatch('clearDateRangeActive');
			},
			applyFilter(val) {
				this.$store.dispatch('setTableFilterValue', val);
			},
			saveFilterPrompt() {
				this.tableFilter['prompt'] = true;
				this.tableFilter['title'] = '';
			},
			saveFilter() {
				this.$root.$emit('saveFilter', this.tableFilter['title']);
			},
			blacklistedFilter(val) {
				this.$root.$emit('blacklistedFilter', val);
			}
		},
		components: {
			VxSidebar,
			TheNavbar,
			TheFooter,
			GlobalFilter,
			BackToTop,
			Datepicker,
			'v-select': vSelect,
		},
		created() {
			this.setSidebarWidth();
		},
		mounted() {
			// Get Blacklisted JSON
			this.$axiosSecure.get('/get-trademarked-keywords')
			.then((response) => {
				this.$store.dispatch('setBlacklistedKeywords', response.data)
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
			});
		},
	}
</script>
